import { Title } from '@solidjs/meta';
import dayjs from '@troon/dayjs';
import { createAsync, useSearchParams } from '@solidjs/router';
import { createStore, produce } from 'solid-js/store';
import { createEffect, createResource, For, Match, Show, Suspense, Switch } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { ActivityIndicator, PillButton, Container, Tabs, TabList, Tab, TabPanel, HorizontalRule } from '@troon/ui';
import { useWindowScrollPosition } from '@solid-primitives/scroll';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Icon } from '@troon/icons';
import { captureException } from '@sentry/solidstart';
import { TeeTimeSearchFields } from '../../components/tee-time-search';
import { gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { dayToDayJs } from '../../modules/date-formatting';
import { useUser } from '../../providers/user';
import { FacilityCtx } from '../../providers/facility';
import { TeeTimeSearchExplainer } from '../../components/tee-time-access-explainer';
import { TeeTimeAccessUpsell } from '../course/[facilityId]/(sub)/reserve-tee-time/components/tee-time-access-upsell';
import { TeeTimesFacility } from './components/tee-times-facility';
import { EmptyState } from './components/empty-state';
import { NoFacilities, NoTeeTimes } from './components/no-results-state';
import { TeeTime } from './components/tee-time';
import { TeeTimeMini } from './components/tee-time-tiny';
import { FacilityFilterPill } from './components/facility-filter';
import type { Resource } from 'solid-js';
import type { CalendarDay, FacilityQuery, TeeTimeFacilitySearchQuery, TeeTimeFragment } from '../../graphql';

type ViewStyle = 'course' | 'time' | 'grid';
type Store = {
	players: number | undefined;
	startAt: number;
	endAt: number;
	date: string;
	regionIds?: string;
	lat: number | undefined;
	lon: number | undefined;
	query: string;
	view: ViewStyle;
	useCard?: boolean;
	supportsAccess?: boolean;
	supportsRewards?: boolean;
	facilities?: string;
	filterFacilities?: string;
};

function queryParamStore<T extends Record<string, unknown>>(
	params: ReturnType<typeof useSearchParams>[0],
	store: T,
): T {
	const out = { ...store };
	for (const [key, val] of Object.entries(out)) {
		if (!(key === 'players' || key === 'lat' || key === 'lon') && typeof params[key] === 'undefined') {
			continue;
		}
		const pVal = params[key] as string;
		if (typeof pVal === 'undefined') {
			continue;
		}
		try {
			if (Array.isArray(val)) {
				// @ts-expect-error TS is weird about the generic here
				out[key] = pVal.split(',');
			} else if (typeof val === 'boolean') {
				// @ts-expect-error TS is weird about the generic here
				out[key] = !!JSON.parse(pVal);
			} else if (key === 'players' || typeof val === 'number') {
				// @ts-expect-error TS is weird about the generic here
				out[key] = parseInt(pVal, 10);
			} else if (key === 'lat' || key === 'lon' || typeof val === 'number') {
				// @ts-expect-error TS is weird about the generic here
				out[key] = parseFloat(pVal);
			} else {
				// @ts-expect-error TS is weird about the generic here
				out[key] = pVal;
			}
		} catch {
			// noop
		}
	}
	return out;
}

export default function TeeTimeSearchPage() {
	const today = dayjs();
	const user = useUser();
	const initialDay = today.hour() < 16 ? today : today.add(1, 'day');
	const scroll = useWindowScrollPosition();

	const [params, setParams] = useSearchParams();
	const [filters, setFilters] = createStore<Store>(
		queryParamStore<Store>(params, {
			players: 2,
			startAt: 0,
			endAt: 24,
			regionIds: undefined,
			facilities: undefined,
			filterFacilities: undefined,
			date: initialDay.format('YYYY-MM-DD'),
			lat: undefined,
			lon: undefined,
			query: '',
			view: 'course',
			useCard: !!user()?.me.card && !user()?.activeTroonCardSubscription,
			supportsAccess: user()?.activeTroonCardSubscription ? true : undefined,
			supportsRewards: undefined,
		}),
	);

	createEffect(() => {
		setParams({ ...filters }, { replace: true });
	});

	const facilities = createAsync(
		async () => {
			const query = {
				location:
					filters.lat && filters.lon ? { latitude: filters.lat, longitude: filters.lon, radiusMiles: 50 } : undefined,
				oldTroonCardGroupId: filters.useCard ? user()?.me.card?.id : undefined,
				slugs: filters.facilities && filters.facilities?.split(',').filter(Boolean),
				regionIds: filters.regionIds && filters.regionIds?.split(',').filter(Boolean),
				supportsAccess: filters.supportsAccess,
				supportsRewards: filters.supportsRewards,
			};

			if (
				!filters.regionIds?.length &&
				!query.slugs?.length &&
				(!query.location?.latitude || !query.location?.longitude)
			) {
				return { allFacilities: [], facilities: [] };
			}
			const res = await getFacilities(query as Parameters<typeof getFacilities>[0]);
			const allFacilities = res?.facilities?.facilities ?? [];

			const filterFacilities = (filters.filterFacilities ?? '').split(',').filter(Boolean);
			const facilities =
				filterFacilities.length === 0 || filterFacilities.length === allFacilities?.length
					? allFacilities
					: (allFacilities?.filter((f) => filterFacilities.includes(f.slug)) ?? []);

			return { all: allFacilities, facilities };
		},
		{ deferStream: true },
	);

	const [maxDate] = createResource(() => {
		const maxDates = facilities.latest?.facilities
			?.flatMap((f) => f.courses.map((c) => dayToDayJs(c.bookingWindowDay as CalendarDay, f.timezone).valueOf()))
			.filter(Boolean);

		return maxDates ? new Date(Math.max(...maxDates)) : undefined;
	});

	return (
		<div>
			<Title>Search for tee times | Troon Rewards</Title>
			<h1 class="sr-only">Search for tee times</h1>

			<div
				class={twJoin(
					'z-30 flex flex-col gap-6 bg-white py-6 transition-all md:sticky md:top-16 md:gap-6',
					scroll.y > 0 && 'md:shadow-md',
				)}
			>
				<Container>
					<div class="flex flex-row flex-wrap items-stretch xl:flex-nowrap">
						<TeeTimeSearchFields
							noButton
							hideShowAllLink
							card={filters.useCard ? user()?.me.card?.id : undefined}
							query={filters.query}
							onSelectPlace={(items) => {
								setFilters(
									produce((s) => {
										s.lat = items?.coordinates.latitude;
										s.lon = items?.coordinates.longitude;
										s.query = items?.name && items?.regionName ? [items.name, items.regionName].join(', ') : '';
									}),
								);
							}}
							onSelectRegion={(region) => {
								setFilters(
									produce((s) => {
										s.lat = undefined;
										s.lon = undefined;
										s.regionIds = region.id;
										s.query = region.displayValue ?? '';
									}),
								);
							}}
							onSelectDate={(date) => setFilters('date', dayjs(date).format('YYYY-MM-DD'))}
							onSelectTimeRange={(startAt, endAt) => {
								setFilters({ startAt, endAt });
							}}
							onSelectPlayers={(count) => {
								setFilters({ players: count });
							}}
							startAt={filters.startAt}
							endAt={filters.endAt}
							date={dayjs(filters.date).toDate()}
							maxDate={maxDate.latest}
							players={filters.players}
						/>
					</div>
				</Container>
			</div>

			<Container>
				<div>
					<div class="mb-4 flex flex-row items-center justify-start gap-4 overflow-x-auto">
						{/* TODO: enable this after we audit facilities (currently incorrect in our DB)
					<PillButton
						aria-pressed={filters.supportsRewards}
						onClick={() => {
							setFilters(
								produce((s) => {
									s.supportsRewards = s.supportsRewards ? undefined : true;
								}),
							);
						}}
					>
						Troon Rewards
						<Show when={filters.supportsRewards}>
							<Icon name="close-circle" />
						</Show>
					</PillButton>*/}
						<Show when={user()?.activeTroonCardSubscription}>
							<PillButton
								aria-pressed={filters.supportsAccess}
								onClick={() => {
									setFilters(
										produce((s) => {
											s.supportsAccess = s.supportsAccess ? undefined : true;
										}),
									);
								}}
							>
								Troon Access
								<Show when={filters.supportsAccess}>
									<Icon name="close-circle" class="shrink-0" />
								</Show>
							</PillButton>
						</Show>
						<Show when={user()?.me.card}>
							{(card) => (
								<PillButton
									aria-pressed={filters.useCard}
									onClick={() => {
										setFilters(
											produce((s) => {
												s.useCard = !s.useCard;
											}),
										);
									}}
								>
									{card().name}
									<Show when={filters.useCard}>
										<Icon name="close-circle" class="shrink-0" />
									</Show>
								</PillButton>
							)}
						</Show>
						<Suspense>
							<Show when={(facilities.latest?.all?.length ?? 0) > 1}>
								<FacilityFilterPill
									facilities={facilities()?.all}
									selected={facilities()?.facilities?.map((f) => f.slug)}
									onSelect={(items) => {
										if (items.length === facilities.latest?.all?.length) {
											setFilters('filterFacilities', '');
											return;
										}
										if (items.length === 0) {
											setFilters('filterFacilities', '_');
											return;
										}
										setFilters('filterFacilities', items.map((i) => i).join(','));
									}}
								/>
							</Show>
						</Suspense>
					</div>
					<Show when={user()?.activeTroonCardSubscription || user()?.me.card}>
						<div class="rounded bg-brand-100 px-4 py-2 text-brand-600">
							<TeeTimeSearchExplainer />
						</div>
					</Show>
				</div>
				<Tabs
					defaultValue={filters.view}
					onChange={(value) => setFilters('view', value as ViewStyle)}
					class="flex flex-col gap-10"
				>
					<TabList aria-label="Tee time views">
						<Tab value="course">
							<Icon name="flag" /> Course
						</Tab>
						<Tab value="time">
							<Icon name="clock" /> Tee time
						</Tab>
					</TabList>
					<TabPanel value={filters.view}>
						<Show
							when={(filters.lat && filters.lon) || filters.regionIds || filters.facilities}
							fallback={
								<EmptyState
									onSelectLocation={(loc) => {
										setFilters({
											query: loc.displayValue,
											lat: loc.coordinates.latitude,
											lon: loc.coordinates.longitude,
										});
									}}
								/>
							}
						>
							<Suspense fallback={<ActivityIndicator />}>
								<Internal
									facilities={facilities()?.facilities as FacilitiesProp}
									players={filters.players}
									startAt={filters.startAt}
									endAt={filters.endAt}
									date={filters.date}
									view={filters.view}
								/>
							</Suspense>
						</Show>
					</TabPanel>
				</Tabs>
			</Container>
		</div>
	);
}

type FacilitiesProp =
	| undefined
	| (TeeTimeFacilitySearchQuery['facilities']['facilities'] & {
			facility: TeeTimeFacilitySearchQuery['facilities']['facilities'][number];
	  });

type InternalProps = {
	facilities?: FacilitiesProp;
	players: number | undefined;
	date: string;
	startAt: number;
	endAt: number;
	view: ViewStyle;
};

function Internal(props: InternalProps) {
	const trackEvent = useTrackEvent();
	const user = useUser();

	const [teeTimes] = createResource(
		() => {
			const courseIds = props.facilities?.flatMap((f) => f.courses.map((c) => c.id));
			if (!courseIds?.length) {
				return { view: props.view };
			}

			const day = dayjs(props.date);

			return {
				facilities: props.facilities,
				courseIds,
				players: props.players,
				startAt: props.startAt,
				endAt: props.endAt,
				year: day.year(),
				month: day.month() + 1,
				day: day.date(),
				view: props.view,
			};
		},
		async (props) => {
			if (!props.facilities) {
				return props.view === 'course' ? {} : [];
			}

			const { facilities, ...filters } = props;
			const { view, ...query } = filters;

			const teeTimesRes = await getTeeTimes(query);
			if (teeTimesRes) {
				trackEvent('getTeeTimes', { ...filters, resultsCount: teeTimesRes?.teeTimes.length });
			}

			const res = teeTimesRes?.teeTimes ?? [];

			if (filters.view === 'course') {
				return facilities?.length
					? facilities.reduce(
							(memo, facility) => {
								facility.courses.forEach((course) => {
									memo[course.id] = ((res as Array<TeeTimeFragment>) ?? []).filter((tt) => tt.courseId === course.id);
								});
								return memo;
							},
							{} as Record<string, Array<TeeTimeFragment>>,
						)
					: {};
			}

			return res;
		},
		{ deferStream: true },
	);

	return (
		<Switch>
			<Match when={props.view === 'course'}>
				<Suspense>
					<Show
						when={
							props.facilities &&
							(teeTimes.state === 'refreshing' || teeTimes.state === 'ready' || teeTimes.state === 'errored')
						}
					>
						<div class="flex flex-col gap-6">
							<For each={props.facilities} fallback={<NoFacilities />}>
								{(facility, i) => (
									<>
										<FacilityCtx.Provider value={() => ({ facility }) as unknown as FacilityQuery}>
											<TeeTimesFacility
												facility={facility}
												teeTimes={teeTimes as Resource<Record<string, Array<TeeTimeFragment>>>}
												players={props.players}
												startAt={props.startAt}
												endAt={props.endAt}
												date={props.date}
											/>
										</FacilityCtx.Provider>
										<Show when={!user()?.activeTroonCardSubscription && i() === 0}>
											<div class="overflow-hidden rounded">
												<TeeTimeAccessUpsell redirect="/tee-times" location="multi-course tee time search" />
											</div>
											<HorizontalRule />
										</Show>
									</>
								)}
							</For>
						</div>
					</Show>
				</Suspense>
			</Match>
			<Match when={props.view === 'time'}>
				<Suspense fallback={<ActivityIndicator />}>
					<ol class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-6">
						<For
							each={teeTimes() as Array<TeeTimeFragment>}
							fallback={
								<div class="col-span-1 sm:col-span-2 lg:col-span-3">
									<NoTeeTimes />
								</div>
							}
						>
							{(teeTime) => (
								<Show
									when={props.facilities?.find((facility) =>
										facility.courses.some(({ id }) => id === teeTime.courseId),
									)}
								>
									{(facility) => (
										<li>
											<TeeTime teeTime={teeTime} facility={facility()} selectedPlayers={props.players} />
										</li>
									)}
								</Show>
							)}
						</For>
					</ol>
				</Suspense>
			</Match>
			<Match when={props.view === 'grid'}>
				<Suspense fallback={<ActivityIndicator />}>
					<ol class="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4 lg:gap-6 xl:grid-cols-6">
						<For
							each={teeTimes() as Array<TeeTimeFragment>}
							fallback={
								<div class="col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-6">
									<NoTeeTimes />
								</div>
							}
						>
							{(teeTime) => (
								<Show
									when={props.facilities?.find((facility) =>
										facility.courses.some(({ id }) => id === teeTime.courseId),
									)}
								>
									{(facility) => (
										<li>
											<TeeTimeMini teeTime={teeTime} facility={facility()} selectedPlayers={props.players} />
										</li>
									)}
								</Show>
							)}
						</For>
					</ol>
				</Suspense>
			</Match>
		</Switch>
	);
}

const facilityQuery = gql(`
query teeTimeFacilitySearch(
	$slugs: [String!],
	$regionIds: [String!],
	$location: FacilityLocationInput,
	$cardGroupId: String
	$supportsTroonAccess: Boolean,
	$supportsTroonRewards: Boolean,
) {
	facilities: facilitiesV3(
		idOrSlugs: $slugs,
		regionIds: $regionIds,
		location: $location,
		oldTroonCardGroupId: $cardGroupId,
		supportsTroonAccess: $supportsTroonAccess,
		supportsTroonRewards: $supportsTroonRewards,
		types: [DAILY_FEE_RESORT, SEMI_PRIVATE]
	) {
		facilities {
			name
			slug
			timezone
			isAvailable
			isBookable
			metadata {
				hero { url }
				address {
					city
					state
				}
				phone
			}
			courses {
				id
				name
				bookingWindowDay { ...Day }
				closures {
					reason
					startDay { ...Day }
					endDay { ...Day }
				}
			}
		}
	}
}
`);

const getFacilities = cachedQuery(facilityQuery);

const teeTimesQuery = gql(`
	query teeTimes(
		$courseIds: [String!]!,
		$year: Int!,
		$month: Int!,
		$day: Int!,
		$startAt: Int!,
		$endAt: Int!,
		$includeCart: Boolean,
		$players: Int
	) {
		teeTimes: courseTeeTimes(
			courseIds: $courseIds,
			day: { year: $year, month: $month, day: $day },
			filters: {
				startAt: { hour: $startAt, minute: 0 },
				endAt: { hour: $endAt, minute: 0 },
				players: $players,
				includeCart: $includeCart,
			}
		) {
			...TeeTime
		}
	}`);

const getTeeTimes = cachedQuery(teeTimesQuery, {
	onError: (e) => {
		captureException(e);
		return null;
	},
});
